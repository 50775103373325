import React from 'react';

import { QuizPage } from "../utils/BasePage";

import translations from "../jsonData/translations.json";

import "../css/QuestionPage.css";
import "../css/EndRelevancePage.css";
import LikertInputProvider from '../utils/LikertInputProvider';
import { useSnackbar } from '../utils/Snackbar';


const RelevancePage = (props) => {

    // Define needed Variables
    let q_id = props.cur_id;
    const data_array = props.data_array;

    let all_question_amount = data_array.filter(entry => entry.type === "question").length;

    const isCompact = props.config.relevance_design === "compact";

    const snackbar = useSnackbar();

    // On Load, set all Non-answered Questions and relevances to Zero.
    if (props.config.relevance.length === 2) {
        props.setEmptyAnswersToZero();
    }

    const clickNext = () => {
        /* Function for the Next button. Checks whether continuing is allowed */

        let is_filled = true;
        data_array.forEach((entry, index) => {
            if (entry.type !== "question") {
                return;
            }

            if (sessionStorage.getItem("relevance" + index) === null) {
                is_filled = false;
            }
        })

        if (!is_filled) {
            snackbar({ id: "question_answer", text: props.theming.text.snack_bar_answer_needed[props.lang] });
            return;
        }

        props.change_pos(q_id + 1);
    };

    const nextButton = (
        // Custom Next button which calls our clickNext function checking whether the question is answered
        <button
            className="btn btn-secondary"
            id="nextButton"
            type="button"
            onClick={clickNext}
        >
            {/* Of course, proper translation for Button */}
            {translations[props.lang].buttons.next}
        </button>
    );

    const renderQuestionField = (data_array_id, current_question_number) => {

        const data = data_array[data_array_id];
        let state = props.config.display_question_as;

        if (state === "question") {
            return (
                <div className="questionSection">

                    {/* Question Counter displaying which question we are on */}
                    <p className="question-counter">
                        {translations[props.lang].questions.counter + " " + current_question_number + "/" + all_question_amount}
                    </p>

                    <h3 className="question-text" >
                        {/* If no proper translation exists, just take the german one. */}
                        {data["text_" + props.lang] === "" || data["text_" + props.lang] === null ? data["text_de"] : data["text_" + props.lang]}
                    </h3>

                    <p className="question-counter" style={{ marginBottom: 0 }}>
                        {translations[props.lang].questions.answer + ":"}
                    </p>

                    <div style={{ width: "70%", margin: "auto" }}>
                        <LikertInputProvider {...props} id={data_array_id} type="question" withText={false} />
                    </div>

                </div>
            )
        } else if (state === "criterion") {
            return (
                <div className="criterionSection">
                    <h3 className="question-text" >
                        {/* If no proper translation exists, just take the german one. */}
                        {data["criterion_" + props.lang] === null ? data["criterion_de"] : data["criterion_" + props.lang]}
                    </h3>
                </div>
            )
        }
    }

    const renderQuestionWithRelevance = (id) => {

        let current_question_number = data_array.slice(0, id + 1).filter(entry => entry.type === "question").length;

        return (
            <div key={id} className="row endRelevanceWrapper">
                {/* QuestionSection. Takes up the whole width if screen is small or extra small, else half of it */}
                <div className={"col-12 col-sm-12 col-md-6" + (isCompact ? " CompactTextRight" : " TextAlignLeft")} >
                    {renderQuestionField(id, current_question_number)}
                </div>

                {/* LikertQuestion. Just as Question, takes up Width when Screen is Small or ExtraSmall, else half of it */}
                <div className="col-12 col-sm-12 col-md-6 answerSection" >

                    {/* Box for Relevance Selection */}
                    <div className={"question-relevance" + (isCompact ? " CompactCube" : "")}>
                        <div className="justify-content-center row">

                            <div key={id} className="relevance-likert">
                                <LikertInputProvider {...props} id={id} type="relevance" withText allowCheckbox />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderAllQuestions = () => {
        return data_array.map((entry, index) => {

            if (entry.type !== "question") {
                return null;
            }

            return renderQuestionWithRelevance(index);
        })
    }

    return (

        <QuizPage
            {...props}
            nextButton={nextButton}
        >

            <main className="row">

                <h3 id="relevance-text">
                    {props.theming.text.relevance_page_header[props.lang]}
                </h3>

                {renderAllQuestions()}

            </main>

        </QuizPage>
    );
}


export default RelevancePage;