import React, { Component } from "react"
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

// Base Page containing basic Placement for the app body, and a Header Bar with Language Buttons, Logo, Progress Bar
import BasePage from "./components/utils/BasePage";

// Question, Activity, and Snack all are Wrapped in the Base Page
import Question from "./components/pages/QuestionPage.js";
import Activity from "./components/pages/ActivityPage.js";
import Wissenssnack from "./components/pages/SnackPage.js";

// Welcome and Result use the WelcomeBasePage, a Wrapper with Background Image
import Welcome from "./components/pages/WelcomePage.js";
import Result from "./components/pages/ResultPage";

// Container for Rendering our custom Snackbar whereever we want
import SnackbarContainer from "./components/utils/Snackbar";

// Function for double checking the answer of a question saved in the SessionStorage
import { sanitize_saved_answer } from "./components/utils/answer_sanitizer";


import { GetFontColorForBackground } from "./components/utils/colours";

import "./App.css"
import "./components/css/AppBody.css"
import "./components/css/Buttons.css"
import RelevancePage from "./components/pages/EndRelevancePage.js";

export default class App extends Component {

  constructor(props) {

    super(props);

    this.state = {

      // Data given by Backend
      dataArray: [],
      /* Data Array has form:
      [
        {
          "type": "question"|"snack"|"activity",
          "pk": int,
          "text_de": "...",
          "text_en": "...",
          "url": "..." (if type is snack or activity)
        },
      ]
      */
      greetingText: {
        pre: "",
        post: "",
      },
      endText: {
        pre: "",
        post: "",
      },
      footer: [],

      config: {
        question: [
          {
            id: 0,
            score: 0,
            text_de: "Stimme nicht zu",
            text_en: "I Disagree"
          },
          {
            id: 1,
            score: 1,
            text_de: "Stimme eher nicht zu",
            text_en: "I would rather disagree"
          },
          {
            id: 2,
            score: 2,
            text_de: "Stimme eher zu",
            text_en: "I would rather agree"
          },
          {
            id: 3,
            score: 3,
            text_de: "Stimme zu",
            text_en: "I agree"
          }
        ],
        relevance_state: "at_question",
        // Either "at_end", or "at_question"
        display_question_as: "criterion",
        // Either "question", or "criterion"
        relevance_design: "compact",
        // Either "normal", or "compact"
        relevance: [
          {
            id: 0,
            score: 0,
            text_de: "Nicht wichtig",
            text_en: "Not important"
          },
          {
            id: 1,
            score: 1,
            text_de: "",
            text_en: ""
          },
          {
            id: 2,
            score: 2,
            text_de: "",
            text_en: ""
          },
          {
            id: 3,
            score: 3,
            text_de: "",
            text_en: ""
          },
          {
            id: 4,
            score: 4,
            text_de: "",
            text_en: ""
          },
          {
            id: 5,
            score: 5,
            text_de: "Sehr wichtig",
            text_en: "Very important"
          }
        ],
      },

      // Default Theming Data to be overwritten
      theming: {
        colour: {

          "--buttons-primary": "#99CC01",
          "--buttons-primary-hover": "#6b9e1f",
          "--buttons-secondary": "#003466",
          "--buttons-secondary-hover": "#003566c7",

          "--primary-progress-bar": "#99CC01",
          "--secondary-progress-bar": "#003466",

          "--text-primary": "#003466",
          "--text-secondary": "#7ea800",

          "--result-list-primary": "#003466",
          "--result-list-secondary": "#d7f0b3b7",

          "--result-list-criterion-bg-colour": "#003466",
          "--result-list-criterion-text-colour": "#ffffff",

        },
        text: {
          // props for replacing text in the Adminfrontend
          result_list_header: "",
          result_list_explainer: {
            de: "Die Prozentanzeige für die Sportartenliste ist für den relativen Vergleich der Sportarten untereinander gedacht. Die anhand Deiner Quizantworten berechnete Bewertung für jede Sportart wird mit dem Sport der höchsten Wertung auf Platz 1 verglichen. So kannst Du erkennen, wie sehr die Sportarten zu Dir passen.",
            en: "The percentage in the sports list is intended for the relative comparison of the sports among each other. The score calculated for each sport based on your quiz answers is compared to the sport with the highest score in the first place. This way you can see how much the sports suit you.",
          },
          snack_bar_activity_done: "",
          snack_bar_answer_needed: "",
          impressum_link: "",
          progress_info: {
            de: "Fast geschafft!",
            en: "Almost done!",
          },
          relevance_page_header: {
            de: "Relevanz",
            en: "Relevance"
          }
        },
        image: {
          "--welcome-background-img": "",

          // These props will not be injected into the CSS, but directly given to their img-elements
          welcome_img: "",
        }
      },

      // Current Quiz Data: Where are we, and what language are we in
      globalIndex: -1,
      lang: "de",

      // Loading is set to false when the theming css variables are applied
      // so that the default values aren't shown the first few frames
      loading: true,

      // Potential Error being thrown
      error: null,
    };

    // https://github.com/facebook/create-react-app/issues/1144
    if (process.env.NODE_ENV === "production") {
      this.backend_ip = "/api/user/quiz/";
    } else {
      this.backend_ip = "http://localhost:8000/api/user/quiz/";
    }


    this.updateGlobalIndex = this.updateGlobalIndex.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.reset = this.reset.bind(this);
    this.customonUnload = this.customonUnload.bind(this);
    this.getMaxValue = this.getMaxValue.bind(this);
    this.getMinValue = this.getMinValue.bind(this);
    this.setEmptyAnswersToZero = this.setEmptyAnswersToZero.bind(this);

    //First History Entry
    window.history.replaceState({ cur_id: -1 }, "Quiz", "/");

    // Setting Current Question according to which ID is given in the History Entry
    window.onpopstate = (event) => {
      if (event.state !== null) {
        this.setState({ globalIndex: event.state.cur_id })
      }
    }

    // Called for every tab closing/switching, so that Quiz Aborts can be measured
    window.addEventListener("visibilitychange", this.customonUnload);
  }

  componentDidMount() {
    // False -> no repeat call, but first call of quiz data
    this.fetchQuizData(false);
  }

  updateGlobalIndex = (newValue) => {
    /**Function given to all Child Elements
     * Resets Scroll and jumps to new Quiz Entry based on the given value
     */

    window.scrollTo({ top: 0, behavior: "instant" });
    this.setState({ globalIndex: newValue });

    window.history.pushState({ cur_id: newValue }, "Quiz", "/");
  }

  setLanguage = (language) => {
    /**Given to Child components
     * Changes active Language
     */
    this.setState(
      {
        lang: language
      }
    )
  }

  getMinValue = (key) => {
    let minScore = Number.MAX_VALUE;
    this.state.config[key].forEach(entry => { if (entry.score < minScore) { minScore = entry.score } });
    return minScore
  }

  getMaxValue = (key) => {
    let maxScore = Number.MIN_VALUE;
    this.state.config[key].forEach(entry => { if (entry.score > maxScore) { maxScore = entry.score } });
    return maxScore
  }

  fetchQuizData(repeat) {
    /**Calls and Applies Quiz and Theming Data from the Backend
     */

    const request = { method: "GET" }
    let address = this.backend_ip;

    // Send Query flag to let backend know that the quiz was repeated
    if (repeat) {
      address += "?restart=1"
    }

    // Callback for the setState used in the fetch request
    const finish_of_fetch = () => {
      this.setCSSvariables();
      this.setState({ loading: false });
    }

    // Get Quiz Info from Backend
    fetch(address, request)
      .then(response => response.json())
      .then(
        (result) => {
          // Enter it to our State
          this.setState({
            dataArray: result.order_list,
            greetingText: result.greeting_texts,
            endText: result.end_texts,
            // Overwrite custom Theming Data with Default Theming Data
            theming: {
              colour: { ...this.state.theming.colour, ...result.theming.colour },
              text: { ...this.state.theming.text, ...result.theming.text },
              image: { ...this.state.theming.image, ...result.theming.image }
            },
            footer: result.footer,
            error: null,
            config: { ...this.state.config, ...result.config },
          },
            // After setState, Callback to apply CSS variables 
            finish_of_fetch);
        },
        (error) => {
          // If the request failed, set the error and nothing else
          this.setState({
            error: error,
            loading: false,
          });
        }
      );
  }

  setCSSvariables() {
    /**Function for setting CSS variables
     * Values are sent from the Backend (since the Adminfrontend can change colours etc)
     * Those Values are applied here
     */

    let root = document.documentElement;

    Object.entries(this.state.theming.colour).forEach(
      ([key, value]) => {
        root.style.setProperty(key, value);
      }
    )
    Object.entries(this.state.theming.image).forEach(
      ([key, value]) => {
        if (key === "--welcome-background-img" && value !== null) {
          // Extra Rule: The one image directly used in css needs to be surrounded by url()
          value = "url(" + value + ")";
          root.style.setProperty(key, value);
        }
      }
    )
    // else, the value is an image or text and will be given to the components through the props

    // Accessibility: We check the chosen colour for the primary and secondary colours.
    // If the colours are light, set another variable to either black or white, deciding the text colour of the button

    let primaryButtonFontColor = GetFontColorForBackground(this.state.theming.colour["--buttons-primary"]);
    root.style.setProperty("--buttons-primary-font", primaryButtonFontColor);

    let secondaryButtonFontColor = GetFontColorForBackground(this.state.theming.colour["--buttons-secondary"]);
    root.style.setProperty("--buttons-secondary-font", secondaryButtonFontColor);

    let sportListTitleFontColor = GetFontColorForBackground(this.state.theming.colour["--result-list-primary"]);
    root.style.setProperty("--result-list-title-font", sportListTitleFontColor);

  }



  reset() {
    /**Reset Function
     * Can be called by ResultPage, to do the entire quiz once again
     * Clears the sessionStorage, calls the quiz data once again and resets the index
     */

    this.setState({ loading: true });

    this.updateGlobalIndex(-1);

    // True set because the quiz is Repeated and the Backend shall know
    this.fetchQuizData(true)

    sessionStorage.clear()
  }

  setEmptyAnswersToZero = () => {
    this.state.dataArray.forEach((entry, index) => {
      if (index > this.state.globalIndex) {
        return;
      }
      if (entry.type !== "question") {
        return;
      }

      if (sessionStorage.getItem("question" + index) === null) {
        sessionStorage.setItem("question" + index, 0);
      }

      if (sessionStorage.getItem("relevance" + index) === null) {
        sessionStorage.setItem("relevance" + index, 0);
      }
    })
  }

  customonUnload = (event) => {
    /**
     * Gets called when the site is hidden
     * Needs to check following things:
     *  - If we are on the Results Page right now, the quiz was completed and we don't need to do anything
     *  - If we are in the quiz right now, we want to gather every answer so far, the current ID we are at, and send that to the Backend
     */

    if (document.visibilityState === 'visible') {
      // The Page was just opened, so we don't need to send anything 
      return;
    }

    if (this.state.globalIndex >= this.state.dataArray.length) {
      // Quiz was finished, so all is well
      return;
    }

    if (this.state.globalIndex === -1 || this.state.dataArray.length === 0) {
      return;
    }

    // Quiz wasn't finished, so we need to alert the Backend
    let quit_at = {};
    if (this.state.globalIndex < this.state.dataArray.length) {
      let cur_page = this.state.dataArray[this.state.globalIndex];
      quit_at = {
        type: cur_page.type,
        pk: cur_page.pk
      }
    }

    // Collects data gathered to this point. Very similar to the gathering in ResultPage
    let data = []
    this.state.dataArray.forEach((entry, index) => {
      if (index > this.state.globalIndex) {
        return;
      }
      if (entry.type !== "question") {
        return;
      }

      let response = {};
      response.pk = entry.pk;
      response.answer = sanitize_saved_answer("question", index, this.state.config["question"].length);
      response.relevance = sanitize_saved_answer("relevance", index, this.state.config["relevance"].length);

      data.push(response);
    });

    let content = new Blob([
      JSON.stringify({
        quit_at: quit_at,
        data: data
      })
    ], {
      type: 'application/json'
    });

    navigator.sendBeacon(this.backend_ip + "quit/", content)
  }


  renderContent() {

    // Waits until all the css variables are applied
    if (this.state.loading) {
      return <div></div>;
    }

    // All Props are given to All Components. Easier this way for an application this size
    let givenProps = {

      // Language State and Setter, needed for text fetching and HeaderBar
      lang: this.state.lang,
      setLanguage: this.setLanguage,

      // Current ID and Setter, needed mostly for Buttons
      cur_id: this.state.globalIndex,
      change_pos: this.updateGlobalIndex,

      // Data Array, needed for reading what is in the current entry
      data_array: this.state.dataArray,

      // Greeting and End Texts, needed for Welcome and Result
      greeting_texts: this.state.greetingText,
      end_texts: this.state.endText,

      // Design given by Backend, needed by HeaderBar, WelcomePage, and ResultPage
      theming: this.state.theming,
      footer: this.state.footer,

      // Reset Function, needed by Result
      reset: this.reset,

      // Backend IP, needed by ResultPage for calling the Sport List
      backend_ip: this.backend_ip,

      // Potential Error thrown by Quiz fetching, needed by Welcome
      error: this.state.error,

      config: this.state.config,
      getMinValue: this.getMinValue,
      getMaxValue: this.getMaxValue,
      setEmptyAnswersToZero: this.setEmptyAnswersToZero,
    }

    //if -1 render Welcome 
    let beginning = this.state.globalIndex < 0;
    let end = this.state.globalIndex >= this.state.dataArray.length;

    let relevance_page = this.state.globalIndex === this.state.dataArray.length;

    if (relevance_page && this.state.config.relevance_state === "at_end") {
      end = false;
    }

    if (beginning) {
      return (
        <Welcome
          {...givenProps}
        />
      )
    }


    if (end) {
      return (
        <Result
          {...givenProps}
        />
      )
    }

    let givenPage;

    if (relevance_page) {
      givenPage = <RelevancePage {...givenProps} />;
    } else {
      switch (this.state.dataArray[this.state.globalIndex].type) {
        case "question":
          givenPage = <Question {...givenProps} />;
          break;
        case "activity":
          givenPage = <Activity {...givenProps} />;
          break;
        case "snack":
          givenPage = <Wissenssnack {...givenProps} />;
          break;
        default:
          givenPage = null;
          break;
      }
    }

    // We need to have the BasePage be unchanged in the DOM-Tree, without unloading it when changing between Question and Activity/Snack
    // Because the Animation of the ProgressBar wouldn't be fluid otherwise
    // This is the reason of the Architecture working around the BasePage as its base
    return (
      <BasePage
        {...givenProps}
      >
        {givenPage}
      </BasePage>
    )
  }

  render = () => {

    return (
      <SnackbarContainer>
        {/* The SnackbarContainer is a Provider which gives access to the Snackbar Hook.
         With it, it's children are able to display a Snackbar when they need to
      */}

        {this.renderContent()}

      </SnackbarContainer>
    );
  }
}
