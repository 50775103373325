import React, { useState } from 'react';
import Likert from 'react-likert-scale';

import { sanitize_saved_answer } from "../utils/answer_sanitizer";

import translations from "../jsonData/translations.json";

import "../css/QuestionPage.css";
import "../css/EndRelevancePage.css";

const RelevanceCheckbox = ({ id, initialChecked, customOnChange, text }) => {
    const [checked, setChecked] = useState(initialChecked);

    const checkboxText = text;
    const checkboxId = id;


    const changeCheckBox = (change) => {
        setChecked(change.target.checked);
        customOnChange(change.target.checked ? 1 : 0);
    }

    return (
        <div key={checkboxId} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <div className="relevanceCheckboxWrapperBox">
                <input id={checkboxId} type="checkbox" checked={checked} onChange={changeCheckBox} className="relevanceCheckbox" />
                <div className="CheckmarkSVG" style={{ visibility: checked ? "visible" : "hidden" }}>
                    <svg fill="white" width='20' height='20' viewBox='0 100 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z' /></svg>
                </div>
            </div>
            {checkboxText === "" || checkboxText === null ?
                (
                    <></>
                )
                :
                (
                    <div className="checkboxText">
                        {checkboxText}
                    </div>
                )
            }
        </div>
    )
}

const LikertInputProvider = ({ type, id, withText = false, allowCheckbox = false, ...props }) => {

    const providerType = type;
    const providerId = id;

    const providerKey = type + id;

    const quizQuestionConfig = props.config;
    const typeConfig = quizQuestionConfig[providerType];

    const getLikertJsonData = () => {
        /*
        Function for constructing the data needed for the Likert Component
        Takes a key (either "question" or "relevance"), accesses the .json files
        QuestionsResponses.json or RelevanceResponses.json depending on the key and given language,
        then sets every .checked to false, and looks in the session storage whether an answer exists already
        */

        let answers = typeConfig;


        // The checked = false needs to be done because answers seems to be cached between different questions

        let formatted_answers = [];

        answers.forEach(entry => {
            let text = null;
            if (withText) {
                text = props.lang === "de" ? entry.text_de : entry.text_en;
            }
            formatted_answers.push({
                checked: false,
                value: entry.id,
                text: text,
            })
        });

        // If an Answer already exists, apply it
        let saved_answer = sanitize_saved_answer(providerType, providerId, typeConfig.length);
        if (saved_answer !== null) {
            formatted_answers[saved_answer].checked = true;
        }


        return formatted_answers;
    }

    const changeSavedItem = (change) => {
        /* Sets sessionStorage for relevant key */
        sessionStorage.setItem(providerKey, change);
    }


    const renderLikert = () => {
        /* Construct a Likert Component for either Question or Relevance, depending on Key */

        let question_answers = getLikertJsonData();

        return (
            //Div with key is needed here, so that the Likert Component is rerendered for every page
            <div key={providerKey}>
                <Likert
                    id={providerKey}
                    key={providerKey}
                    responses={question_answers}
                    onChange={change => changeSavedItem(change.value)}
                    title={translations[props.lang].accessibility[providerType]}
                    aria-labelledby={providerKey}
                />
            </div>
        )
    }

    const renderCheckbox = () => {
        let question_answers = getLikertJsonData();
        let text;

        switch (question_answers.length) {
            case 0:
                text = null;
                break;
            case 1:
                text = question_answers[0].text;
                break;
            default:
                text = question_answers[1].text;
                break;
        }

        return (
            <div key={providerKey}>
                <RelevanceCheckbox id={providerKey} key={providerKey} initialChecked={question_answers[1].checked ? 1 : 0} text={text} customOnChange={(change) => changeSavedItem(change)} />
            </div>
        )
    }

    return typeConfig.length > 2 || !allowCheckbox ? (
        renderLikert()
    ) : (
        renderCheckbox()
    );
}


export default LikertInputProvider;